import * as Turbo from "@hotwired/turbo";
Turbo.start();

import Rails from "@rails/ujs";
Rails.start();
window.Rails = Rails;

import SlimSelect from "slim-select";

import L from "leaflet";
import "../controllers";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "@rails/actiontext";
import "../js/action_text";

import "../css/application.scss";
