import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.check();
  }

  check() {
    const catsCount = this.element.querySelectorAll(
      ".nested-form-wrapper"
    ).length;

    if (catsCount > 1) {
      this.itemTargets.forEach((item) => {
        item.style.display = "block";
      });
    } else {
      this.itemTargets.forEach((item) => {
        item.style.display = "none";
      });
    }
  }

  remove(event) {
    if (
      confirm(
        "Katze wirklich entfernen? Die eingebenen Daten dieser Katze gehen verloren."
      )
    ) {
      const catElement = event.target.closest(".nested-form-wrapper");

      if (catElement) {
        catElement.parentNode.removeChild(catElement);
      }

      this.check();
    }
  }
}
