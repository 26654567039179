import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    var latitude = this.data.get("latitude");
    var longitude = this.data.get("longitude");
    var zoom = 7;

    var map = L.map(this.element, {
      zoomControl: false,
      boxZoom: false,
      doubleClickZoom: false,
      dragging: false,
      touchZoom: false,
      scrollWheelZoom: false,
      keyboard: false,
    }).setView([latitude, longitude], zoom);

    // Other tile providers: https://leaflet-extras.github.io/leaflet-providers/preview/
    L.tileLayer("https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: '<a href="https://www.openstreetmap.org">OSM</a>',
      maxZoom: zoom,
    }).addTo(map);

    L.marker([latitude, longitude]).addTo(map);

    // Mittelpunkt der Schweiz
    map.panTo(new L.LatLng(46.7985124, 8.2295887));
  }
}
