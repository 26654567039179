import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "element"];
  static values = { url: String };

  connect() {
    this.update();
  }

  update() {
    const newValue = this.sourceTarget.value;
    const newHref = this.urlValue + `?cat_id=${newValue}`;

    if (newValue && newValue.trim().length > 0) {
      this.elementTargets.forEach((element) => {
        element.href = newHref;
        element.classList.toggle("hidden", false);
      });
    } else {
      this.elementTargets.forEach((element) => {
        element.classList.toggle("hidden", true);
      });
    }
  }
}
