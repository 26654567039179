import SlimSelect from "slim-select";
import SlimSelectController from "./slim_select_controller";

export default class extends SlimSelectController {
  static values = {
    create: Boolean,
    options: Object,
  };

  connect() {
    let addable = {};

    if (this.createValue) {
      addable = {
        addable: (value) => value,
        searchPlaceholder: "Suche / Neuer Eintrag",
      };
    }

    this.slimselect = new SlimSelect({
      select: this.element,
      searchPlaceholder: "Suche",
      searchText: "Nichts gefunden",
      placeholder: "Eintrag auswählen",
      ...addable,
      ...this.optionsValue,
    });
  }
}
