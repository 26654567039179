import Trix from "trix";
window.Trix = Trix;

Trix.config.lang = {
  bold: "Fett",
  bullets: "Liste",
  byte: "Byte",
  bytes: "Bytes",
  captionPlaceholder: "Bildunterschrift hinzufügen…",
  code: "Code",
  heading1: "Titel",
  indent: "Einzug vergrössern",
  italic: "Kursiv",
  link: "Link",
  numbers: "Aufzählung",
  outdent: "Einzug verkleinern",
  quote: "Zitat",
  redo: "Wiederherstellen",
  remove: "Entfernen",
  strike: "Durchgestrichen",
  undo: "Rückgängig",
  unlink: "Link entfernen",
  url: "URL",
  urlPlaceholder: "URL eingeben…",
  GB: "GB",
  KB: "KB",
  MB: "MB",
  PB: "PB",
  TB: "TB",
};

const toolbarHTML = () => {
  const { lang } = Trix.config;

  return `
  <div class="trix-button-row">
     <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
       <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">#{lang.bold}</button>
       <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
       <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${lang.attachFiles}</button>
     </span>
     <span class="trix-button-group-spacer"></span>
     <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
       <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${lang.undo}</button>
       <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${lang.redo}</button>
     </span>
   </div>
   <div class="trix-dialogs" data-trix-dialogs>
     <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
       <div class="trix-dialog__link-fields">
         <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" data-trix-input>
         <div class="trix-button-group">
           <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
           <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}" data-trix-method="removeAttribute">
         </div>
       </div>
     </div>
   </div>
`;
};

const updateToolbars = () => {
  const toolbars = document.querySelectorAll("trix-toolbar");
  const html = Trix.config.toolbar.getDefaultHTML();
  toolbars.forEach((toolbar) => {
    if (toolbar.id !== "trix-toolbar-khf") {
      toolbar.innerHTML = html;
    }
  });
};

Trix.config.attachments.preview.caption.name = false;
Trix.config.attachments.preview.caption.size = false;
Trix.config.toolbar.getDefaultHTML = toolbarHTML;

document.addEventListener("trix-initialize", updateToolbars, { once: true });
