import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };

import Carousel from "stimulus-carousel";
import { Dropdown } from "tailwindcss-stimulus-components";
import Reveal from "stimulus-reveal-controller";
import Lightbox from "stimulus-lightbox";
import NestedForm from "stimulus-rails-nested-form";

import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "@node_modules/lightgallery/css/lightgallery.css";
import "@node_modules/slim-select/dist/slimselect.min";

application.register("carousel", Carousel);
application.register("dropdown", Dropdown);
application.register("reveal", Reveal);
application.register("lightbox", Lightbox);
application.register("nested-form", NestedForm);

// Flatpickr
import Flatpickr from "stimulus-flatpickr";
import { German } from "flatpickr/dist/l10n/de";
flatpickr.localize(German);
import "flatpickr/dist/flatpickr.css";
application.register("flatpickr", Flatpickr);
