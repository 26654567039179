import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "button", "yes", "no"];
  static values = { visible: Boolean, deleteId: Number };

  initialize() {
    this.formHTML = this.formTarget.innerHTML;

    this.update();
  }

  show() {
    this.visibleValue = true;
    this.update();
  }

  hide() {
    this.visibleValue = false;
    this.update();
  }

  update() {
    this.updateChoice();
    this.setFormVisibility();
  }

  updateChoice() {
    this.yesTarget.checked = this.visibleValue;
    this.noTarget.checked = !this.visibleValue;
  }

  setFormVisibility() {
    if (this.visibleValue) {
      this.formTarget.innerHTML = this.formHTML;
    } else {
      if (this.deleteIdValue) {
        this.formTarget.innerHTML =
          '<input type="hidden" name="member[secondary_person_attributes][id]" value="' +
          this.deleteIdValue +
          '" /><input type="hidden" name="member[secondary_person_attributes][_destroy]" value="1" />';
      } else {
        this.formTarget.innerHTML = "";
      }
    }
  }
}
