import { Controller } from "@hotwired/stimulus";

// Adapted from https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/tabs.js
export default class extends Controller {
  static classes = ["activeTab", "inactiveTab"];
  static targets = ["tab", "panel", "select"];
  static values = {
    index: -1,
  };

  connect() {
    if (this.anchor) {
      this.indexValue = this.tabTargets.findIndex(
        (tab) => tab.dataset.id === this.anchor,
      );
    }

    if (this.indexValue < 0) this.indexValue = 0;

    this.showTab();
  }

  // Changes to the clicked tab
  change(event) {
    if (event.currentTarget.tagName === "SELECT") {
      this.indexValue = event.currentTarget.selectedIndex;

      // If target specifies an index, use that
    } else if (event.currentTarget.dataset.index) {
      this.indexValue = event.currentTarget.dataset.index;

      // If target specifies an id, use that
    } else if (event.currentTarget.dataset.id) {
      this.indexValue = this.tabTargets.findIndex(
        (tab) => tab.dataset.id == event.currentTarget.dataset.id,
      );

      // Otherwise, use the index of the current target
    } else {
      this.indexValue = this.tabTargets.indexOf(event.currentTarget);
    }

    window.dispatchEvent(new CustomEvent("tsc:tab-change"));
  }

  nextTab() {
    this.indexValue = Math.min(this.indexValue + 1, this.tabsCount - 1);
  }

  previousTab() {
    this.indexValue = Math.max(this.indexValue - 1, 0);
  }

  firstTab() {
    this.indexValue = 0;
  }

  lastTab() {
    this.indexValue = this.tabsCount - 1;
  }

  indexValueChanged() {
    if (this.indexValue < 0) return;

    this.showTab();

    const newTab = this.tabTargets[this.indexValue].dataset.id;
    const url = new URL(window.location);

    if (url.searchParams.get("tab") !== newTab) {
      url.searchParams.set("tab", newTab);

      history.pushState({}, "", url.href);
    }
  }

  showTab() {
    this.panelTargets.forEach((panel, index) => {
      const tab = this.tabTargets[index];

      if (index === this.indexValue) {
        panel.classList.remove("hidden");
        if (this.hasInactiveTabClass)
          tab?.classList?.remove(...this.inactiveTabClasses);
        if (this.hasActiveTabClass)
          tab?.classList?.add(...this.activeTabClasses);
      } else {
        panel.classList.add("hidden");
        if (this.hasActiveTabClass)
          tab?.classList?.remove(...this.activeTabClasses);
        if (this.hasInactiveTabClass)
          tab?.classList?.add(...this.inactiveTabClasses);
      }
    });

    if (this.hasSelectTarget) {
      this.selectTarget.selectedIndex = this.indexValue;
    }
  }

  get tabsCount() {
    return this.tabTargets.length;
  }

  get anchor() {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get("tab");
  }
}
