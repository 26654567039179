import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  increase() {
    this.changeValue(1);
  }

  decrease() {
    this.changeValue(-1);
  }

  changeValue(factor) {
    let quantity_input = document.getElementById(this.data.get("inputid"));

    if (quantity_input) {
      let quantity = quantity_input.value ? parseInt(quantity_input.value) : 0;

      if (quantity != NaN) {
        let new_quantity = quantity + factor;

        if (new_quantity < 0) new_quantity = 0;

        quantity_input.value = new_quantity;
      }
    }
  }
}
