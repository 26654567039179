import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.element.addEventListener("change", (event) => {
      let urls = JSON.parse(this.data.get("urls"));

      window.location = urls[event.target.value];
    });
  }
}
