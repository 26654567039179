import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  set(event) {
    const newValue = event.target.value;

    this.elementTargets.forEach((element) => (element.value = newValue));
  }
}
